
import {
  defineComponent, ref
} from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import useComposeOpenModal from '@/compositions/compose/useComposeOpenModal'

const autoUnsubscribeOptionsVariant = [
  '1 sent message with status “Failed”',
  '2 sent message with status “Failed”',
]

export default defineComponent({
  name: 'ContactsUnsubscribedSettingsModal',
  components: {
    TmButton,
    TmModal,
    TmAlert,
    TmFormLine,
  },
  setup() {
    const optOutConfirmation = ref(false)
    const autoUnsubscribe = ref(false)
    const smsChatSettings = ref(false)
    const templateIncluded = ref(false)

    const optOutConfirmationCheckbox = ref(false)
    const { openModalInsertTemplate } = useComposeOpenModal()
    const openInsertTemplate = () => {
      openModalInsertTemplate()
      templateIncluded.value = true
    }

    const autoUnsubscribeValue = ref('1 sent message with status “Failed”')

    return {
      optOutConfirmation,
      optOutConfirmationCheckbox,
      openInsertTemplate,
      templateIncluded,
      autoUnsubscribe,
      openModalInsertTemplate,
      smsChatSettings,
      autoUnsubscribeOptionsVariant,
      autoUnsubscribeValue,
    }
  },
})
